/*
  Screen size legend: (in width)
  - Smallest mobile screen size is: 320px
  - 360px
  - 375px
  - 412px
  - 414px
  - 480px
  =- Tablet sizes
  - 600px
  - 768px
  - 800px
  =- Tablet/laptop sizes
  - 1024px
  - 1130px
  - 1280px
  - 1360px
  =- Desktop sizes
  - 1366px
  - 1920px

  Basic usage:
  .selector {
    color: red;
    
    @include breakpoint('phone-small') {
      color: blue;
    }
  }
*/

$breakpoints: (
  "phone-iphone5": (
    min-width: 320px
  ),
  "phone-average": (
    min-width: 360px
  ),
  "phone-iphone6": (
    min-width: 375px
  ),
  "phone-pixel-2": (
    min-width: 411px
  ),
  "iphone-plus": (
    min-width: 414px
  ),
  "phone-large": (
    min-width: 480px
  ),
  "tablet-small": (
    min-width: 512px
  ),
  "surface-duo": (
    min-width: 540px
  ),
  "tablet": (
    min-width: 768px
  ),
  "tablet-medium": (
    min-width: 960px
  ),
  "tablet-large": (
    min-width: 1024px
  ),
  "bddc-screen": (
    min-width: 1130px
  ),
  "laptop": (
    min-width: 1280px
  ),
  "laptop-large": (
    min-width: 1360px
  ),
  "desktop": (
    min-width: 1400px
  ),
  "desktop-wide": (
    min-width: 1440px
  ),
  "desktop-hd": (
    min-width: 1600px
  ),
  "HDReady": (
    min-width: 1920px
  ),
  "HD": (
    min-width: 1921px
  ),
  "4k": (
    min-width: 2048px
  ),
  "5k": (
    min-width: 2880px
  ),
  "6k": (
    min-width: 3800px
  ),
  "7k": (
    min-width: 5500px
  )
);
@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
