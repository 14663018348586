@import "./../core-theme.scss";
@import "./../mixins/modules/style-helpers/breakpoint-mixin";

.bddc {
  .photo-album {
    .ngx-image-placeholder {
      padding: 0 !important;
      width: 87px;
      height: 84px;

      @include breakpoint("tablet") {
        width: 186px;
        height: 122px;
      }

      @include breakpoint("tablet-large") {
        width: 242px;
        height: 143px;
      }

      img {
        width: 100%;
        height: auto;
        min-height: 100%;

        &.placeholder-loading-image {
          transform: none;

          @include breakpoint("tablet") {
            top: -30px;
          }

          @include breakpoint("tablet-large") {
            top: -50px;
          }
        }
      }
    }

    &_grid {
      .ngx-image-placeholder {
        background-color: $placeHolderImageColor;
        overflow: hidden;
        width: 130%;
        height: auto;
        min-height: 100%;
        padding: 0 !important;

        img {
          &.placeholder-loading-image {
            height: 100%;
            width: auto;
          }
        }
      }
    }

    &_flex-image {
      .ngx-image-placeholder {
        padding: 0 !important;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: auto;
          min-height: 100%;

          &.placeholder-loading-image {
            transform: none;

            @include breakpoint("tablet-large") {
              top: 0;
            }
          }
        }
      }
    }
  }

  .photos {
    .ngx-image-placeholder {
      padding: 0 !important;
      width: 87px;
      height: 84px;

      @include breakpoint("tablet") {
        width: 154px;
        height: 124px;
      }

      @include breakpoint("tablet-large") {
        width: 242px;
        height: 143px;
      }

      img {
        &.placeholder-loading-image {
          width: 100%;
          height: auto;
          min-height: 100%;
          transform: none;

          @include breakpoint("tablet-large") {
            top: -50px;
          }
        }

        &.loaded {
          width: 100%;
          height: auto;
          min-height: 100%;
        }
      }
    }
  }

  .photos {
    .compact-photo-album {
      .ngx-image-placeholder {
        padding: 0 !important;
        width: 100%;
        height: 100%;

        img {
          &.placeholder-loading-image {
            width: 100%;
            height: auto;
            min-height: 100%;
            transform: none;
            top: 0;
          }

          &.loaded {
            width: 100%;
            height: auto;
            min-height: 100%;
          }
        }
      }

      .side-image-overlay {
        .ngx-image-placeholder {
          width: 94px;
          height: 84px;
        }
      }
    }
  }
}
