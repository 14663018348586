@import "./../../../core-theme";
@mixin change-mat-font-color() {
	.bddc {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		a,
		span.mat-body-1 {
			color: $primary-blue;
			&.mat-secondary {
				color: $light-blue;
			}
			&.red-text {
				color: $red;
			}
		}
	}
}