@import "./../core-theme";
@import "./../mixins/modules/style-helpers/breakpoint-mixin";

.bddc {
  .bddc-calendar_bullet-point {
    display: none;
    max-width: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: none;

    &.grey {
      color: $calendarGrey;
    }

    .calendar-request-bullet {
      color: $orange;
    }

    @include breakpoint("tablet-large") {
      display: block;
      margin-left: 8px;
      color: $purple;
      font-weight: bold;
      font-size: 9px;

      span:first-child:not(.calendar-request-bullet) {
        color: $purple;
      }
    }
  }

  .fc-h-event.fc-event {
    border: none;
  }

  .fc-view-harness.fc-view-harness-active {
    overflow: hidden;
  }

  // START
  // TODO: reuse these styles properly for both versions of the calendar
  .fc-day.fc-daygrid-day.sleepover-day {
    background-image: url("../../images/icons/moon_icon.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 35%;

    @include breakpoint("tablet-large") {
      background-color: #f3f8fe;
      background-position: right 10px top 10px;
      background-size: 20px 20px;
    }
  }

  .fc-day.fc-daygrid-day.daycare-day {
    background-image: url("../../images/icons/sun_icon.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 40%;

    @include breakpoint("tablet-large") {
      background-color: #fffae5;
      background-position: right 10px top 10px;
      background-size: 20px 20px;
    }
  }

  .fc-day.fc-daygrid-day.holiday {
    background-image: url("../../images/icons/calendar-holiday_icon.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 40%;

    @include breakpoint("tablet-large") {
      background-color: transparent;
      background-position: right 10px top 10px;
      background-size: 20px 20px;
    }
  }

  .sleepover-day-grey {
    background-image: url("../../images/icons/moon_grey_icon.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 35%;

    .fc-day-grid-event.fc-h-event.fc-event {
      color: red;
    }

    @include breakpoint("tablet-large") {
      background-position: right 10px top 10px;
      background-size: 20px 20px;
    }
  }

  .daycare-day-grey {
    background-image: url("../../images/icons/sun_grey_icon.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 40%;

    @include breakpoint("tablet-large") {
      background-position: right 10px top 10px;
      background-size: 20px 20px;
    }
  }

  .holiday-grey {
    background-image: url("../../images/icons/calendar-holiday-past_icon.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 40%;
    opacity: 0.5;

    @include breakpoint("tablet-large") {
      background-position: right 10px top 10px;
      background-size: 20px 20px;
    }
  }

  // END
  .fc-dayGridMonth-view table thead tr,
  .fc-dayGridMonth-view table tbody tr .fc-scrollgrid-section table tr {
    border-color: transparent;
  }

  // .fc-dayGridMonth-view table tbody tr .fc-scrollgrid-section table tr {
  //   min-height: 0;
  //   @include breakpoint("tablet-large") {
  //     min-height: 4em;
  //   }
  // }

  .fc-daygrid-day .fc-highlight {
    position: relative;
    background: transparent;
    opacity: 1;

    &:before {
      content: "";
      position: absolute;
      width: 39px;
      height: 39px;
      border: 3px solid $primary-blue;
      border-radius: 50%;
      left: 2px;
      top: -1px;

      @include breakpoint("tablet-large") {
        content: initial;
        opacity: 0;
      }
    }
  }

  .fc-button:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .has-service {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $purple;
      bottom: 4px;
      right: 20px;

      @include breakpoint("tablet-large") {
        display: none;
      }
    }
  }

  .has-request {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $orange;
      bottom: 4px;
      right: 20px;

      @include breakpoint("tablet-large") {
        display: none;
      }
    }
  }

  .fc-button-primary {
    background-color: transparent;
    border-color: transparent;
  }

  .fc-button-primary:hover {
    background-color: transparent;
    border-color: transparent;
  }

  .fc-button-primary:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .fc-button-primary:not(:disabled):active,
  .fc-button-primary:not(:disabled).fc-button-active {
    background-color: transparent;
    border-color: transparent;
  }

  .fc-button-primary:not(:disabled):active:focus,
  .fc-button-primary:not(:disabled).fc-button-active:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .fc-toolbar h2 {
    color: $light-blue !important;
    font: 700 16px/20px Lato, sans-serif;

    @include breakpoint("tablet-large") {
      font: 700 20px/27px Lato, sans-serif;
    }
  }

  .fc-button .fc-icon {
    color: $blue-gray;
  }

  .fc-scrollgrid.fc-scrollgrid-liquid .fc-scroller-harness .fc-scroller .fc-col-header {
    background-color: $soft-blue;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;

    .fc-col-header-cell-cushion {
      color: $menuBarShadowColor;
    }
  }

  .fc-toolbar.fc-header-toolbar {
    max-height: 46px;
    margin-bottom: 0 !important;
    background-color: $white;
    border-radius: 16px 16px 0 0;

    @include breakpoint("tablet-large") {
      max-height: 64px;
      height: 64px;

      .fc-toolbar-chunk {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }

    .fc-toolbar-chunk {
      &:first-child {
        border-right: 1px solid $soft-blue-2;
      }

      &:last-child {
        border-left: 1px solid $soft-blue-2;
      }

      .fc-button-primary {
        background: transparent;
        border: none;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .fc-col-header-cell.fc-day {
    width: 14.29%; // (100% divided by 7, rounds off to this number)
    border: 1px solid transparent;

    @include breakpoint("tablet-large") {
      border-top: transparent;
      border-bottom: transparent;
      border-right: 1px solid $soft-blue-2;
      width: calc(14.29% - 1px);
      padding: 7px 0;

      &:first-child {
        border-left: transparent;
      }

      &:last-child {
        border-right: transparent;
      }
    }
  }

  table.fc-col-header {
    .fc-col-header-cell {
      border: 1px solid transparent;
    }
  }

  .fc-dayGridMonth-view table tbody tr .fc-scrollgrid-section table tr td {
    .fc-daygrid-day-number {
      color: $gray-blue !important;
    }

    &.fc-day-today {
      .fc-daygrid-day-number {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;

        @include breakpoint("tablet-large") {
          padding: 11px 14px !important;
          display: initial;
          flex-direction: initial;
          justify-content: initial;
          align-items: initial;
        }
      }
    }
  }

  .fc-direction-ltr .fc-dayGridMonth-view .fc-daygrid-day-top .fc-daygrid-day-number {
    margin-right: auto;
    padding: 11px 14px;
    color: $gray-blue;
  }

  .fc-dayGridMonth-view table thead tr,
  .fc-dayGridMonth-view table tbody tr .fc-scrollgrid-section table tr {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-spacing: 0px 0px;
    border-collapse: separate;
  }

  .fc-scrollgrid-section-header {
    .fc-col-header {
      // fixes inline styling injection
      border-right-width: 0 !important;
      margin-right: 0 !important;
      border: 0;
    }
  }

  .fc-view-harness.fc-view-harness-active {
    // fixes inline styling injection
    overflow: hidden !important;
    height: 346px !important;

    @include breakpoint("tablet-large") {
      height: 387px !important;
    }

    @include breakpoint("bddc-screen") {
      height: 447px !important;
    }

    @include breakpoint("laptop") {
      height: 521px !important;
    }
  }

  .fc td {
    .fc-daygrid-bg-harness {
      @include breakpoint("tablet-large") {
        border: 1px solid $primary-blue;
        opacity: 1;
        background: transparent;
      }
    }
  }

  .fc tr {
    &:last-child {
      td {
        border-bottom: transparent;

        @include breakpoint("phone-iphone5") {
          border-left: none;
          border-right: none;
        }

        @include breakpoint("bddc-screen") {
          border-left: 0.75px solid #80808047;
          border-right: 0.75px solid #80808047;
        }
      }
    }
  }

  .fc-view-harness>table {
    background-color: $white;
  }

  tr:first-child table {
    border-top: 0;
  }

  tr .fc-daygrid-day-top {
    padding-bottom: 0;
    height: 14px;

    @include breakpoint("tablet-large") {
      height: initial;
    }
  }

  .fc-daygrid-event-harness {
    display: none;

    @include breakpoint("tablet") {
      display: block;
    }
  }

  .fc-event-title.fc-sticky {
    display: none;
  }

  tr {
    &:not(:last-child) .fc-bg {
      border-bottom: 1px solid $soft-blue-2;

      @include breakpoint("tablet-large") {
        border-bottom: 0;
      }
    }
  }

  .fc-daygrid-block-event.fc-h-event.fc-event {
    pointer-events: none;

    .elipsed-service {
      color: $purple;
    }
  }

  .fc-bgevent {
    /* default look for background events */
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;

    @include breakpoint("tablet-large") {
      position: absolute;
      width: 112px;
      height: 91px;
      margin-left: 1px;
      margin-top: 1px;
      padding-top: 0;
      display: initial;
      align-items: initial;
      justify-content: initial;
    }

    img {
      width: 35px;
      height: 35px;

      @include breakpoint("tablet-large") {
        width: 18px;
        height: 18px;
        margin: 10px 9px;
      }

      &.sun-icon {
        @include breakpoint("tablet-large") {
          width: 30px;
          height: 30px;
          margin: 16px 7px;
        }
      }
    }
  }

  .fc-event-container {
    position: relative;

    @include breakpoint("tablet-large") {
      position: initial;
    }

    .fc-day-grid-event.fc-h-event.fc-event {
      position: absolute;
      background: initial;
      border: 0;
      color: $purple;
      margin-left: 17px;
      font-size: 11px;
      font-weight: bold;
      bottom: 0;
      width: 100%;

      @include breakpoint("tablet-large") {
        position: relative;
        bottom: 0;
        width: initial;
      }

      &:before {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $purple;
        top: 0;
        left: calc(14.29% / 2);

        @include breakpoint("tablet-large") {
          width: 5px;
          height: 5px;
          top: 9px;
          left: -10px;
        }
      }

      .fc-title {
        display: none;

        @include breakpoint("tablet-large") {
          display: initial;
        }
      }
    }
  }

  .fc-day-past {
    &:not(.fc-other-month) {
      filter: grayscale(1);
    }

    .bddc-calendar_bullet-point {
      color: $purple;
    }
  }
}

/*
  ========== Mini-calendar-picker theme: ==========
*/

.bddc {
  .mini-calendar.fc.fc-theme-standard {
    font-family: $base-font;

    .fc-scrollgrid.fc-scrollgrid-liquid .fc-scroller-harness .fc-scroller .fc-col-header {
      background-color: $soft-blue;
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;

      .fc-col-header-cell-cushion {
        color: $menuBarShadowColor;
      }
    }

    a.fc-more {
      display: none;

      @include breakpoint("desktop") {
        display: initial;
        font-size: 12px;
        margin: 1px 15px;
      }
    }

    .fc-dayGridMonth-view tbody tr {
      min-height: initial;

      @include breakpoint("desktop") {
        min-height: 4em;
      }
    }

    .fc-daygrid-day .fc-highlight {
      position: relative;
      background: transparent;
      opacity: 1;

      &:before {
        content: "";
        position: absolute;
        width: 39px;
        height: 39px;
        border: 3px solid $primary-blue;
        border-radius: 50%;
        left: 2px;
        top: -1px;

        @include breakpoint("tablet-large") {
          content: initial;
          opacity: 0;
        }
      }
    }

    .fc-button:focus {
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .fc-day.fc-daygrid-day.sleepover-day {
      background-image: url("../../images/icons/moon_icon.svg");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 35%;

      @include breakpoint("tablet-large") {
        background-color: #f3f8fe;
        background-position: right 10px top 10px;
        background-size: 20px 20px;
      }
    }

    .fc-day.fc-daygrid-day.daycare-day {
      background-image: url("../../images/icons/sun_icon.svg");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 40%;

      @include breakpoint("tablet-large") {
        background-color: #fffae5;
        background-position: right 10px top 10px;
        background-size: 20px 20px;
      }
    }

    .fc-day.fc-daygrid-day.holiday {
      background-image: url("../../images/icons/calendar-holiday_icon.svg");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 40%;

      @include breakpoint("tablet-large") {
        background-color: transparent;
        background-position: right 10px top 10px;
        background-size: 20px 20px;
      }
    }

    .sleepover-day-grey {
      background-image: url("../../images/icons/moon_grey_icon.svg");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 35%;

      .fc-day-grid-event.fc-h-event.fc-event {
        color: red;
      }

      @include breakpoint("tablet-large") {
        background-position: right 10px top 10px;
        background-size: 20px 20px;
      }
    }

    .daycare-day-grey {
      background-image: url("../../images/icons/sun_grey_icon.svg");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 40%;

      @include breakpoint("tablet-large") {
        background-position: right 10px top 10px;
        background-size: 20px 20px;
      }
    }

    .holiday-grey {
      background-image: url("../../images/icons/calendar-holiday-past_icon.svg");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 40%;
      opacity: 0.5;

      @include breakpoint("tablet-large") {
        background-position: right 10px top 10px;
        background-size: 20px 20px;
      }
    }

    .fc-button-primary {
      background-color: transparent !important;
      border-color: transparent;

      &:hover {
        background-color: transparent !important;
        border-color: transparent;
      }

      &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    .fc-button-primary:not(:disabled):active,
    .fc-button-primary:not(:disabled).fc-button-active {
      background-color: transparent !important;
      border-color: transparent;
    }

    .fc-button-primary:not(:disabled):active:focus,
    .fc-button-primary:not(:disabled).fc-button-active:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .fc-toolbar h2 {
      color: $light-blue !important;
      font: 700 16px/20px Lato, sans-serif;

      @include breakpoint("desktop") {
        font: 700 20px/27px Lato, sans-serif;
      }
    }

    .fc-button .fc-icon {
      color: $blue-gray;
    }

    .fc-head {
      background-color: $soft-blue;
      color: $menuBarShadowColor;
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;
    }

    .fc-toolbar.fc-header-toolbar {
      max-height: 46px;
      margin-bottom: 0 !important;
      background-color: $white;
      border-radius: 16px 16px 0 0;

      @include breakpoint("desktop") {
        max-height: 60px;
        height: 60px;

        .fc-toolbar-chunk {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }

      .fc-toolbar-chunk {
        &:first-child {
          border-right: 1px solid $soft-blue-2;
        }

        &:last-child {
          border-left: 1px solid $soft-blue-2;
        }

        .fc-button-primary {
          background: transparent;
          border: none;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    .fc-col-header-cell.fc-day {
      width: 14.29%; // (100% divided by 7, rounds off to this number)
      height: 20px;
      border: 1px solid transparent;
      vertical-align: middle;

      @include breakpoint("desktop") {
        border-top: transparent;
        border-bottom: transparent;
        border-right: transparent;
        width: calc(14.29% - 1px);
        height: 30px;
        padding: 0;

        &:first-child {
          border-left: transparent;
        }

        &:last-child {
          border-right: transparent;
        }
      }
    }

    td {

      // fix
      &.fc-col-header {
        border: 1px solid transparent;
      }

      &.fc-day-today {
        background-color: rgba(255, 220, 40, 0.15) !important;

        .fc-daygrid-day-number {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: bold;

          @include breakpoint("desktop") {
            padding: 11px 14px !important;
            display: initial;
            flex-direction: initial;
            justify-content: initial;
            align-items: initial;
          }
        }
      }
    }

    .fc-ltr .fc-dayGridMonth-view .fc-daygrid-day-top .fc-daygrid-day-number {
      @include breakpoint("bddc-screen") {
        float: left;
        padding: 11px 14px;
      }

      @include breakpoint("desktop") {
        color: $gray-blue;
      }
    }

    .fc-scrollgrid-sync-table tbody tr {
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      border-spacing: 0 0;
      border-collapse: separate;
    }

    .fc-col-header {
      // fixes inline styling injection
      border-right-width: 0 !important;
      margin-right: 0 !important;
      border: 0;
    }

    .fc-view-harness.fc-view-harness-active {
      // fixes inline styling injection
      overflow: hidden !important;
      height: 346px !important;

      @include breakpoint("tablet-large") {
        height: 522px !important;
      }

      @include breakpoint("desktop") {
        height: 527px !important;
      }
    }

    .fc-daygrid-body .fc-daygrid-day {
      width: 40.5px;
      height: 53px;

      @include breakpoint("tablet-large") {
        width: 100%;
        height: 55px;
      }

      @include breakpoint("desktop") {
        border: 1px solid $soft-blue-2;
        width: 106px;
        height: 85px;

        &:first-child {
          border-left: transparent;
        }

        &:last-child {
          border-right: transparent;
        }
      }

      &.selected-date {
        @include breakpoint("desktop") {
          border: 1px solid $primary-blue;
        }
      }

      &.fc-day-today {
        background-color: transparent;
        // border: 0;
        border-bottom: 1px solid $soft-blue-2;
        border-top: 1px solid $soft-blue-2;

        @include breakpoint("desktop") {
          .fc-scrollgrid-sync-inner {
            border: 1px solid $primary-blue;
          }

          background: $calendarSelectedDayBg;
        }

        &.no-border {
          .fc-daygrid-day-frame {
            border: 0;
          }
        }
      }
    }

    tr .fc-daygrid-day {
      &:last-child {
        td {
          border-bottom: transparent;
        }
      }
    }

    .fc-view-harness>table {
      background-color: $white;
    }

    tr:first-child table {
      border-top: 0;
    }

    tr .fc-daygrid-day-top {
      padding-bottom: 0;
      height: 14px;

      @include breakpoint("desktop") {
        height: initial;
      }
    }

    tr {
      &:not(:last-child) .fc-bg {
        border-bottom: 1px solid $soft-blue-2;

        @include breakpoint("desktop") {
          border-bottom: 0;
        }
      }
    }
  }
}