@import "./../../../core-theme";
@import "./../style-helpers/breakpoint-mixin";

@mixin rounded-input() {
  .bddc {
    .mat-form-field {
      width: 267px;
      height: $inputHeightSize;

      @include breakpoint("tablet") {
        width: 300px;
      }

      &.mat-paginator-page-size-select {
        text-align: center;
      }

      &.large {
        width: 288px;

        @include breakpoint("phone-average") {
          width: 341px;
        }

        .mat-form-field-flex {
          width: 100%;
        }

        @include breakpoint("tablet") {
          width: 378px;
        }
      }

      &.feedback_textarea {
        width: 100%;

        .mat-form-field-flex {
          width: 100%;
        }
      }

      input::placeholder {
        color: $gray-blue-2;
      }

      input:-ms-input-placeholder {
        color: $gray-blue-2;
      }

      input::-ms-input-placeholder {
        color: $gray-blue-2;
      }

      &.mat-form-field-invalid {
        input::placeholder {
          color: $red;
        }

        input:-ms-input-placeholder {
          color: $red;
        }

        input::-ms-input-placeholder {
          color: $red;
        }

        mat-icon {
          color: $red;
        }
      }

      &-label-wrapper {
        * {
          font-family: $base-font;
          color: $gray-blue-2;
        }
      }

      &.mat-focused .mat-form-field-underline {
        display: none;
      }

      &.mat-focused {
        .mat-form-field-flex {
          border: 1px solid $primary-blue;
        }

        * {
          &:not(.mat-icon) {
            color: $primary-blue;
          }
        }
      }

      &.ng-valid {
        color: $primary-blue;
      }

      &:not(.mat-form-field-hide-placeholder) {
        .mat-form-field-label-wrapper {
          top: -2em;
          left: 12px; // as per design
        }
      }

      &-infix {
        border-top: 0;
        padding: 0 1em;

        input,
        textarea {
          color: $primary-blue;
        }
      }

      &.search,
      &.datepicker {
        .mat-form-field-infix {
          padding-bottom: 0;
          padding-left: 0;
          margin-left: 12px;
        }
      }

      &.search {
        .mat-form-field-infix {
          span {
            &.mat-form-field-label-wrapper {
              top: -23px;
              height: 25px;
            }
          }
        }

        &.photos-search {
          .mat-form-field-infix {
            padding-top: 0;
          }
        }
      }

      &.datepicker {
        .mat-form-field-infix {
          padding: 0;
          margin-top: 0;

          input {
            height: 40px;
            pointer-events: none;
            padding-top: 0;
            line-height: 1;
          }

          .mat-form-field-label-wrapper {
            top: -9px;
          }
        }
      }

      &-prefix {
        padding-left: $spacingLarge;
      }

      &-suffix {
        padding-right: $spacingSmall;

        .mat-icon-button {
          margin-top: 0;
          margin-right: 0;
        }
      }

      &-flex {
        width: 223px;
        height: $inputHeightSize;
        border-radius: $borderRadiusSize;
        background-color: $soft-blue;
        align-items: center;

        @include breakpoint("phone-iphone6") {
          width: 267px;
        }

        @include breakpoint("tablet") {
          width: 300px;
        }
      }

      &.mat-form-field-invalid {
        input {
          color: $red;
          -webkit-text-fill-color: $red;
        }

        .mat-form-field-flex {
          border: 1px solid $red;
          background-color: $light-pink;
        }

        .mat-form-field-label-wrapper {
          * {
            color: $red;
          }
        }
      }

      &-appearance-legacy {
        .mat-form-field-underline {
          height: 0;
        }

        &.mat-form-field-invalid {
          &:not(.mat-focused) {
            .mat-form-field-ripple {
              height: 0;
            }
          }
        }
      }

      &.textarea {
        height: 83px;

        .mat-form-field-flex {
          width: 319px;
          height: 83px;
          border-radius: 0;
          background-color: transparent;
          align-items: initial;

          .mat-form-field-label {
            &.mat-form-field-empty {
              left: 1em;
            }
          }
        }

        .mat-form-field-infix {
          padding: 0;
        }

        &.mat-focused,
        &.ng-valid {
          .mat-form-field-flex {
            border: none;
            padding: 0;

            textarea {
              border: 1px solid $primary-blue;
              color: $primary-blue;
            }
          }
        }
      }

      &.login-form-field,
      &.password-form-field {
        width: 100%;

        .mat-form-field-infix {
          padding-left: 12px;
        }

        .mat-form-field-flex {
          width: 100%;
        }
      }

      textarea {
        width: 319px;
        height: 83px;
        border-radius: $borderRadiusSmallSize;
        background-color: $soft-blue;
        padding: $spacingLarge;
      }

      &-infix {
        span {
          &.mat-form-field-label-wrapper {
            top: 10px;
            padding-top: 0;
          }
        }
      }
    }
  }
}