@import "./../../core-theme";

@mixin change-ng-select-style() {
  .bddc {
    .ng-select {
      .ng-select-container {
        background-color: $soft-blue;
        color: $primary-blue;
        width: 223px;
        height: $inputHeightSize;
        border-radius: $borderRadiusSize;
        border: 0;
        font-family: $base-font;
        transition: initial;

        @include breakpoint("phone-iphone6") {
          width: 267px;
        }
        @include breakpoint("tablet") {
          width: 311px;
        }

        .ng-value-container {
          .ng-placeholder,
          .ng-value {
            color: $primary-blue;
          }

          .ng-input {
            height: $inputHeightSize;
            padding: 0;

            & > input {
              // Added font size to prevent zoom effect
              font-size: 16px;
              height: 100%;
              cursor: pointer;
            }
          }
        }
      }

      .ng-arrow-wrapper {
        pointer-events: none;

        .ng-arrow {
          width: $squareInputSmallSize;
          height: $squareInputSmallSize;
          color: $primary-blue;
        }
      }

      &.ng-select-opened {
        .ng-select-container {
          border-radius: $borderRadiusSize $borderRadiusSize 0 0;
          border: 1px solid $primary-blue;
          border-bottom: 0;
          box-shadow: none;

          .ng-arrow-wrapper {
            .ng-arrow {
              transform: rotate(135deg);
            }
          }
        }

        &.ng-select-top {
          .ng-select-container {
            border-radius: 0 0 $borderRadiusSize $borderRadiusSize;
            border: 1px solid $primary-blue;
            border-top: 0;
          }
        }
      }
    }

    .ng-dropdown-panel {
      &.ng-select-bottom {
        width: 100%;
        border-radius: 0 0 $borderRadiusSize $borderRadiusSize;
        margin: 0;
        box-shadow: none;
        border: 1px solid $primary-blue;
        border-top: 0;
        overflow: hidden;
        background-color: $soft-blue;
      }

      &.ng-select-top {
        width: 100%;
        border-radius: $borderRadiusSize $borderRadiusSize 0 0;
        margin: 0;
        box-shadow: none;
        border: 1px solid $primary-blue;
        border-bottom: 0;
        overflow: hidden;
        background-color: $soft-blue;
      }

      .ng-dropdown-panel-items {
        .ng-option {
          background-color: transparent;
          font-family: $base-font;
          height: 37px;
          border-top: 1px solid $soft-blue-2;

          &:not(.ng-option-disabled) {
            color: $primary-blue;
          }

          &:hover {
            background-color: $soft-blue-2;
          }

          &.ng-option-selected {
            font-weight: initial;

            &.ng-option-marked {
              background-color: $soft-blue-2;
              font-weight: initial;
            }
          }
        }
      }
    }
  }
  .dynamic-service-dialog_overlay {
    .dynamic_service-dialog {
      .mat-form-field-flex {
        width: 287px;
        .mat-form-field-infix {
          margin-top: 6px;
          padding-left: 0;
        }
      }
    }
  }
}
