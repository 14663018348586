@use '@angular/material' as mat;
@import "./../../../core-theme";

@mixin raised-rounded-button() {

  // Override the default styling for the button
  .bddc {
    mat-card-content {
      .mat-raised-button {
        width: 92px;
        height: 35px;
      }
    }

    .mat-raised-button {
      border-radius: $borderRadiusSize;
      width: 182px;
      height: $inputHeightSize;
      color: $primary-blue;
      font-size: $generalFontSize;
      text-transform: uppercase;

      &:not([class*="mat-elevation-z"]) {
        box-shadow: 0 2px 6px $boxShadowColorRGBA  !important;
      }

      &:hover,
      &:active {
        background-color: $primary-blue;
        color: $white;
      }

      &.mat-footer {
        box-shadow: none;
        color: $primary-blue;

        &:hover {
          background-color: rgba(255, 255, 255, 0.7);
          box-shadow: none;
          color: $primary-blue;
        }

        &:active {
          background-color: rgba(255, 255, 255, 0.3);
          box-shadow: none;
          color: $white;
        }
      }

      &.mat-primary {
        color: $white;

        &:hover,
        &:active {
          background-color: $dark-blue;
          box-shadow: none;
          color: $white;
        }

        &.compact {
          color: $white;
        }
      }

      &.mat-secondary {
        color: $primary-blue;

        &:hover,
        &:active {
          color: $white;
        }
      }

      &.mat-warn {
        color: $white;

        &:hover,
        &:active {
          background-color: $red-2;
          box-shadow: none;
        }
      }

      &.mat-white-transparent {
        color: $white;
        background-color: rgba($white, 0.3);
        width: 82px;
        height: 35px;
        text-transform: initial;
        padding: 8px 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        // IE FIX:
        text-transform: none;

        @include breakpoint("phone-iphone6") {
          width: 101px;
        }

        @include breakpoint("tablet") {
          width: 111px;
        }

        &:hover,
        &:active {
          background-color: rgba($white, 0.7);
        }

        span {
          &.mat-button-wrapper {
            line-height: 17px;
            font-size: 12px;

            @include breakpoint("phone-iphone6") {
              font-size: 14px;
            }

            @include breakpoint("tablet-large") {
              font-size: 15px;
            }
          }
        }

        &.is-favourite {
          color: $red;
          background-color: $white;
        }
      }

      &.rounded-edge {
        border-radius: 5px;
        text-transform: initial;

        &.mat-white-transparent {
          border: 1px solid $white;
          width: auto;

          @include breakpoint("phone-iphone6") {
            width: 143px;
            height: 25px;
            padding: 4px 10px;

            span.mat-button-wrapper {
              font-size: 14px;
              line-height: 1;
            }
          }

          @include breakpoint("tablet-large") {
            width: 156px;
            height: 27px;
            padding: 4px 12px 5px 12px;
            // IE FIX:
            text-transform: none;
          }

          .mat-button-wrapper {
            @include breakpoint("tablet-large") {
              line-height: 1;
              font-size: 15px;
            }
          }

          .mat-button-focus-overlay {
            border-radius: 5px;
          }
        }
      }

      &.compact {
        width: 75px;
        height: 35px;

        &-extended {
          width: 125px;
        }
      }

      &.overlay {
        background-color: rgba($white, 0.35);
        border: 1px solid $white;
        border-radius: 5px;
        color: $white;
        width: 70px;
        height: 27px;
        text-transform: none;
        line-height: normal;
        padding: 0;

        &:hover,
        &:active {
          color: $primary-blue;
        }

        &:hover {
          background-color: rgba($white, 1);
        }

        &:active {
          opacity: 0.7;
        }
      }

      &.outlined {
        border: 1px solid mat.get-color-from-palette($primary);
      }

      &.social-button {
        width: 283px;
        height: 45px;
        text-transform: uppercase;
        background-color: $white;

        &.google {
          border: 1px solid $google-gray-1;
          color: $google-gray-2;

          &:hover,
          &:active {
            background-color: $white;
            box-shadow: 0 4px $boxShadowBlurSize $google-gray-1;
            color: $google-gray-2;
          }
        }

        &.facebook {
          border: 1px solid $facebook-color;
          color: $facebook-color;

          &:hover,
          &:active {
            box-shadow: 0 4px $boxShadowBlurSize rgba($facebook-color, 0.2);
          }

          .mat-icon {
            .a {
              fill: $facebook-color;
            }
          }

          &-share {
            border: 1px solid $facebook-color;
            color: $facebook-color;
            width: 210px;
            height: 45px;
            box-shadow: none;

            &:hover,
            &:active {
              box-shadow: 0 4px $boxShadowBlurSize rgba($facebook-color, 0.2);
            }

            .mat-icon {
              .a {
                fill: $facebook-color;
              }
            }
          }
        }

        &.twitter {
          border: 1px solid $twitter-color;
          color: $twitter-color;
          width: 210px;
          box-shadow: none;

          &:hover,
          &:active {
            box-shadow: 0 4px $boxShadowBlurSize rgba($twitter-color, 0.2);
          }

          .mat-icon {
            .a {
              fill: $twitter-color;
            }
          }
        }

        &.email {
          border: 1px solid $gray-blue;
          color: $gray-blue;
          width: 210px;
          box-shadow: none;

          &:hover,
          &:active {
            box-shadow: 0 4px $boxShadowBlurSize rgba($gray-blue, 0.2);
          }

          .mat-icon {
            margin-right: $spacingSmall;
            // using mat-icons svg:
            color: $gray-blue;
            line-height: 0.825em;

            .a {
              fill: $gray-blue;
            }
          }
        }

        .mat-icon {
          width: 17px;
          height: 17px;
          vertical-align: text-top;
          display: inline-flex;
        }
      }

      &:disabled {
        pointer-events: none;
        color: rgba(0, 0, 0, 0.26);
      }

      &.cancel-button {
        margin: 10px 0 0 0 !important;

        @include breakpoint("bddc-screen") {
          margin: 0 10px 0 8px !important;
        }

        &.medium {
          padding-top: 4px;
          margin: 10px 0 0 0 !important;
        }

        &.large {
          padding-top: 4px;
          margin: 20px 0 0 0 !important;
        }
      }

      &.longer-button {
        width: 190px;
      }

      &.toolbar_button {
        font-size: 12px;
        line-height: 12px;
        min-width: 140px;
        width: auto;
        height: 34px;

        &:hover {
          .mat-icon {
            color: white;
          }
        }
      }
    }
  }
}