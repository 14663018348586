@use "@angular/material" as mat;
@import "./core-theme";
@import "./mixins/modules/style-helpers/breakpoint-mixin";

.u {
  &-cursor {
    &-pointer {
      cursor: pointer;
    }

    &-none {
      pointer-events: none;
    }
  }

  &-width {
    &-half {
      @include breakpoint("laptop") {
        width: 49%;
      }
    }
  }

  &-flex {
    display: flex;

    &-full {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include breakpoint("laptop") {
        flex-direction: row;
      }
    }

    &-center {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-row {
      display: flex;
      flex-direction: row;

      &.wrap {
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
    }

    &-start {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    &-end_center {
      width: 100%;
      display: flex;
      place-content: center flex-end;
      align-items: center;
    }

    &-start_center {
      width: 100%;
      display: flex;
      place-content: center flex-start;
      align-items: center;
    }

    // Don't want content to be aligned center on the cross axis
    &-center_no-align {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &.grow {
      flex: 1 1 100%;
    }

    &.initial {
      flex: 0 1 auto;
    }
  }

  &-content {
    &_before {
      margin-top: 2em;
    }
  }

  &-heading {
    &_primary {
      color: mat.get-color-from-palette($primary);
      margin-top: 0;
    }
  }

  &-hidden {
    display: none !important;
  }

  &-text {
    &-no-margin {
      margin: 0 !important;
    }

    &-center {
      text-align: center;
    }

    &-heading {
      &_light-blue {
        &.mat-display-2 {
          font-size: 25px;
          color: $light-blue;
        }
      }
    }

    &-color {
      &_white {
        color: $white  !important;
      }

      &_gray-blue {
        color: $blue-gray  !important;
      }

      &_gray-blue-dark {
        color: $gray-blue  !important;
      }

      &_green {
        color: $green  !important;
      }

      &_red {
        color: $red;
      }
    }

    &-size {
      &_15-override {
        font-size: 15px !important;
      }
    }

    &-bold {
      font-weight: bold !important;
    }

    &-capitalize {
      text-transform: capitalize;
    }

    &-asterix {
      -webkit-text-security: disc;
    }

    &-weight {
      &_300 {
        font-weight: 300;
      }
    }
  }

  &-has_curved-header {
    display: flex;
    flex-direction: column;
    padding: 30px;
    position: fixed;
    top: 9em;
    z-index: 3;
    width: calc(100% - 61px); // removes the padding and absolute positioning for the curve shape

    @include breakpoint("phone-pixel-2") {
      top: 15em;
    }

    @include breakpoint("tablet") {
      top: 50vh;
    }

    @include breakpoint("laptop") {
      background-color: $white;
      border-radius: $borderRadiusMediumSize;
      position: relative;
      width: calc(378px - 60px);
      height: calc(386px - 60px);
      margin: 0 auto;
      top: 20vh;
    }
  }

  &-mobile-only {
    @include breakpoint("laptop") {
      display: none;
    }
  }

  &-space-v {
    &_30 {
      padding: 30px 0;
    }
  }

  &-icon {
    &-medium {
      &.mat-icon {
        width: 18px;
        height: 18px;
      }
    }
  }

  &-bg {
    &_white {
      background-color: $white;
    }
  }

  &-centered {
    text-align: center;
  }

  &-loader-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

button {
  background-color: $white;
}

.bddc {
  svg {
    .a {
      stroke: initial;
    }
  }

  svg .remove {
    fill: $red;
  }

  button {
    &:disabled {
      svg .remove {
        fill: rgba(0, 0, 0, 0.12);
      }
    }
  }

  .mobile-menu_link {
    span {
      color: $white;
    }

    svg {
      .a {
        stroke: $white;
        fill: $white;
      }
    }
  }

  .mat-drawer-container {
    background-color: $white  !important; // override the default - normal definition doesn't work sadly
  }
}

// Color INDEX
.gray-blue {
  color: $gray-blue;
}

.gray-blue_override {
  color: $gray-blue  !important;
}

.bddc-accent {
  color: $blue-gray;
}

.bddc-dark-blue {
  color: $primary-blue;
}

.light-blue {
  color: $light-blue;

  &_override {
    color: $light-blue  !important;
  }
}

.soft-blue-2 {
  color: $soft-blue-2;
}

.overflow {
  &_hidden {
    overflow: hidden;
  }
}

// Height Index
.height {
  &_100_percent {
    height: 100%;
  }

  &_500 {
    height: 500px;
  }

  &_200 {
    height: 200px;
  }

  &_108 {
    height: 108px;
  }

  &_83 {
    height: 83px;
  }

  &_74 {
    height: 74px;
  }

  &_72 {
    height: 72px;
  }

  &_60 {
    height: 60px;
  }

  &_30 {
    height: 30px;
  }

  &_20 {
    height: 20px;
  }

  &_18 {
    height: 18px;
  }
}

.width-percentage {
  &_100 {
    width: 100%;
  }
}

.width-max {
  &_378 {
    max-width: 378px;
  }
}

.width {
  &_684 {
    width: 684px;
  }

  &_378 {
    width: 378px;
  }

  &_287 {
    width: 287px;
  }

  &_287-override {
    width: 287px !important;
  }

  &_fill-override {
    width: -webkit-fill-available !important;
  }
}

.width-important {
  &_684 {
    width: 684px !important;
  }
}

// Margin INDEX
.margin-bottom {
  &_60 {
    margin-bottom: 60px;
  }

  &_30 {
    margin-bottom: 30px;
  }

  &_25 {
    margin-bottom: 25px;
  }

  &_20 {
    margin-bottom: 20px;
  }

  &_17 {
    margin-bottom: 17px;
  }

  &_15 {
    margin-bottom: 15px;
  }

  &_10 {
    margin-bottom: 10px;
  }

  &_10-override-default {
    margin-bottom: 10px !important;
  }

  &_0 {
    margin-bottom: 0;
  }

  &_10-default-override {
    margin-bottom: 10px !important;
  }

  &_10-override {
    @media (max-width: 1024px) {
      margin-bottom: 10px !important; // important is used to force override
    }
  }

  &_inherit-override {
    margin-bottom: inherit !important;
  }
}

.margin-top {
  &_35 {
    margin-top: 35px;
  }

  &_30 {
    margin-top: 30px;
  }

  &_25 {
    margin-top: 25px;
  }

  &_22 {
    margin-top: 22px;
  }

  &_20 {
    margin-top: 20px;
  }

  &_20-override {
    margin-top: 20px !important;
  }

  &_17 {
    margin-top: 17px;
  }

  &_15 {
    margin-top: 15px;
  }

  &_10 {
    margin-top: 10px;
  }

  &_10-override {
    margin-top: 10px !important;
  }

  &_0-override {
    margin-top: 0 !important;
  }
}

.margin-left-less {
  &_20 {
    margin-left: -20px;
  }

  &_15 {
    margin-left: -15px;
  }
}

.margin-left-important {
  &_97 {
    margin-left: 97px !important;
  }

  &_80 {
    margin-left: 80px !important;
  }
}

.margin-right-less {
  &_20 {
    margin-right: -20px;
  }
}

.margin-left {
  &_86 {
    margin-left: 86px;
  }

  &_30 {
    margin-left: 30px;
  }

  &_15 {
    margin-left: 15px;
  }

  &_5 {
    margin-left: 5px;
  }

  &_auto {
    margin-left: auto;
  }

  &_auto-override {
    margin-left: auto !important;
  }

  &_0 {
    margin-left: 0;
  }
}

.margin-right {
  &_86 {
    margin-right: 86px;
  }

  &_60 {
    margin-right: 60px;
  }

  &_42 {
    margin-right: 42px;
  }

  &_30 {
    margin-right: 30px;
  }

  &_15 {
    margin-right: 15px;
  }

  &_10 {
    margin-right: 10px;
  }

  &_5 {
    margin-right: 5px;
  }
}

.margin-right-important {
  &_10 {
    margin-right: 10px !important;
  }
}

.margin {
  &_20 {
    margin: 20px;
  }

  &_no-sides-30 {
    margin: 30px 0;
  }

  &_none-override {
    margin: 0 !important;
  }
}

// Padding INDEX
.padding {
  &_20 {
    padding: 20px;
  }

  &_10 {
    padding: 10px;
  }

  &_none {
    padding: 0px !important;
  }
}

.padding-top {
  &_30 {
    padding-top: 30px;
  }

  &_20 {
    padding-top: 20px;
  }

  &_15 {
    padding-top: 15px;
  }

  &_13 {
    padding-top: 13px;
  }

  &_10 {
    padding-top: 10px;
  }
}

.padding-bottom {
  &_20 {
    padding-bottom: 20px;
  }

  &_15 {
    padding-bottom: 15px !important;
  }

  &_13 {
    padding-bottom: 13px;
  }

  &_11 {
    padding-bottom: 11px;
  }

  &_10 {
    padding-bottom: 10px;
  }
}

.padding-left {
  &_86 {
    padding-left: 86px;
  }

  &_20 {
    padding-left: 20px;
  }

  &_15 {
    padding-left: 15px;
  }

  &_none {
    padding-left: 0px;
  }
}

.padding-right {
  &_86 {
    padding-right: 86px;
  }

  &_15 {
    padding-right: 15px;
  }

  &_none {
    padding-right: 0px;
  }
}

.padding-sides {
  &_20 {
    padding: 0 20px;
  }
}

// Style Index
.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 600;
}

.border-radius {
  &_16 {
    border-radius: 16px;
  }
}

.border-none {
  border: none;
}

// Font Sizes INDEX
.font-size {
  &_16 {
    font-size: 16px;
  }
}

.font-weight {
  &_700 {
    font-weight: 700 !important;
  }
}