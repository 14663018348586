@import "./../../core-theme";
@import "./../../mixins/modules/style-helpers/breakpoint-mixin";
@mixin change-ngx-gallery-styling() {
  $carouselSpacing: 30px;
  $carouselIconSize: 30px;
  $carouselDesktopAlignment: 360px;
  // use of important - as nothing else will work
  .bddc {
    ngx-gallery {
      &.photo-carousel {
        max-height: 340px;
        .ngx-gallery-layout.thumbnails-bottom {
          ngx-gallery-image {
            width: 100%;
            height: 230px !important;
            @include breakpoint("laptop") {
              width: auto;
              height: 274px !important;
              margin-left: 13.5%;
            }
            @include breakpoint("laptop-large") {
              margin-left: 15%;
            }
            @include breakpoint("desktop") {
              margin-left: 32.5%;
            }
            @media (width: 1920px) {
              margin-left: 35%;
            }
            @include breakpoint("HD") {
              margin-left: 38.75%;
            }
            ngx-gallery-arrows {
              position: absolute;
              width: calc(99vw - #{$carouselSpacing});
              height: 100%;
              z-index: 0;
              left: 16.5%;
              transform: translate(-#{$carouselDesktopAlignment}, 0px);
              @include breakpoint("tablet") {
                left: 36.5%;
              }
              @include breakpoint("bddc-screen") {
                left: 33%;
              }
              @include breakpoint("laptop") {
                left: 15%;
              }
              @include breakpoint("laptop-large") {
                left: 110px;
              }
              @media (width: 1366px) {
                left: 11.5%;
              }
              @include breakpoint("desktop") {
                left: -110px;
              }
              @include breakpoint("desktop-wide") {
                left: -14%;
              }
              @include breakpoint("desktop-hd") {
                left: -18%;
              }
              @include breakpoint("HDReady") {
                left: -27.5%;
              }
              @include breakpoint("HD") {
                width: 95vw;
                left: -360px;
              }
              @include breakpoint("4k") {
                left: -410px;
              }
              @include breakpoint("5k") {
                left: -24vw;
              }
              @include breakpoint("6k") {
                left: -27.5vw;
              }
              @include breakpoint("7k") {
                left: -30.5vw;
              }
              .ngx-gallery-arrow-wrapper {
                i {
                  &.ngx-gallery-icon-content {
                    width: 15px;
                    height: 26px;
                    color: $primary-blue;
                  }
                }
              }
            }
          }
          ngx-gallery-thumbnails {
            width: 289px;
            height: 57px !important;
            margin: 10px 0 0 0 !important;
            @include breakpoint("phone-average") {
              width: 313px;
              margin: 10px 0 0 10px !important;
            }
            @include breakpoint("phone-iphone6") {
              margin: 10px 0 0 15px !important;
            }
            @include breakpoint("phone-pixel-2") {
              width: 321px;
              margin: 10px 0 0 30px !important;
            }
            @include breakpoint("tablet") {
              margin: 10px auto 0 auto !important;
            }
            @include breakpoint("laptop") {
              width: 330px;
              margin-left: 35% !important;
            }
            @include breakpoint("laptop-large") {
              width: 330px;
              margin-left: 36.25% !important;
            }
            @include breakpoint("desktop") {
              margin-left: 37.5% !important;
            }
            @include breakpoint("desktop-wide") {
              margin-left: 37%;
            }
            @include breakpoint("HDReady") {
              margin-left: 42% !important;
            }
            @include breakpoint("HD") {
              margin-left: 43% !important;
            }
            @include breakpoint("7k") {
              margin-left: 47.25% !important;
            }
            a {
              width: 49px !important;
              height: 49px !important;
              border-radius: 8px;
              pointer-events: none;
              @include breakpoint("phone-average") {
                width: 57px !important;
                height: 57px !important;
              }
              @include breakpoint("phone-iphone6") {
                width: 55px !important;
                height: 55px !important;
              }
              .ngx-gallery-remaining-count-overlay {
                border-radius: 8px;
                .ngx-gallery-remaining-count {
                  color: $white;
                  text-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.2);
                  font-family: $base-font;
                  font-size: 15px;
                }
              }
            }
          }
        }
        .ngx-gallery-image-wrapper {
          width: 288px;
          overflow: visible;
          @include breakpoint("phone-average") {
            width: 311px;
          }
          @include breakpoint("phone-iphone6") {
            width: 321px;
          }
          @include breakpoint("phone-pixel-2") {
            width: 340px;
            left: 20px;
          }
          @include breakpoint("tablet") {
            width: 330px;
            left: 40px;
          }
          @include breakpoint("tablet-large") {
            left: 172px;
            width: 325px;
          }
          @include breakpoint("bddc-screen") {
            left: 220px;
          }
          @include breakpoint("laptop") {
            width: $carouselDesktopAlignment;
            margin-left: 0;
            left: 236px;
          }
          @include breakpoint("laptop-large") {
            left: 248px;
          }
          @media (width: 1366px) {
            left: 251px;
          }
          @include breakpoint("desktop") {
            left: 61px;
          }
          @include breakpoint("desktop-hd") {
            left: 71px;
          }
          @include breakpoint("HDReady") {
            left: 121px;
          }
          @include breakpoint("HD") {
            left: 73px;
          }
          @include breakpoint("4k") {
            left: 78px;
          }
          @include breakpoint("5k") {
            left: 116px;
          }
          @include breakpoint("6k") {
            left: 156px;
          }
          @include breakpoint("7k") {
            left: 456px;
          }
        }
        .ngx-gallery {
          &-animation-slide {
            .ngx-gallery-image.ngx-gallery-inactive-left {
              left: -320px !important;
              @include breakpoint("phone-average") {
                left: -310px !important;
              }
              @include breakpoint("phone-pixel-2") {
                left: -310px !important;
              }
              @include breakpoint("tablet") {
                left: -50% !important;
              }
              @include breakpoint("tablet-large") {
                left: -52% !important;
              }
              @include breakpoint("laptop") {
                left: -340px !important;
              }
            }
          }
          &-image {
            border-radius: 16px;
            &.ngx-gallery-inactive {
              &-left,
              &-right {
                width: 311px;
                height: 200px;
                top: 10px;
                @include breakpoint("laptop") {
                  width: 310px;
                  height: 230px;
                  top: 0;
                }
              }
              &-right {
                left: 298px !important;
                @include breakpoint("phone-average") {
                  left: 107% !important;
                }
                @include breakpoint("phone-pixel-2") {
                  left: 100% !important;
                }
                @include breakpoint("tablet") {
                  left: 505px !important;
                }
                @include breakpoint("laptop") {
                  left: 100% !important;
                }
              }
            }
            &.ngx-gallery-active {
              cursor: pointer;
              width: 288px;
              height: 230px;
              @include breakpoint("phone-average") {
                width: 311px;
                left: 10px !important;
              }
              @include breakpoint("phone-iphone6") {
                width: 321px;
              }
              @include breakpoint("tablet") {
                left: 50% !important;
              }
              @include breakpoint("laptop") {
                width: 330px;
                height: 244px;
                left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
