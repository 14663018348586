@import "./../../../core-theme";

@mixin change-radio-button-color() {
  .bddc {
    .mat-radio-button {
      &.mat-primary {
        .mat-radio {
          &-outer-circle,
          &-inner-circle {
            width: $squareInputSize;
            height: $squareInputSize;
          }
          &-outer-circle {
            border-width: initial;
            border-style: none;
            background-color: $soft-blue-2;
          }
        }
        &.mat-radio-checked {
          .mat-radio {
            &-outer-circle {
              border-width: $borderWidthSmallSize;
              border-style: solid;
            }
            &-inner-circle {
              background-color: $primary-blue;
              border: 1px solid $primary-blue;
              width: 32px;
              height: 32px;
              left: -1px;
              top: -1px;
            }
          }
        }
        .mat-radio-ripple {
          left: calc(50% - 15px);
          top: calc(50% - 15px);
        }
      }
      .mat-radio-label-content {
        color: $primary-blue;
        padding-left: 16px;
        padding-top: $spacingSmall;
      }
    }
  }
}
