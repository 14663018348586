@import "./../../../core-theme";
@mixin full-width-button() {
  // Override the default styling for the button
  .bddc {
    .mat-button {
      border-radius: 0;
      background-color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.full-size-button {
        width: 100%;
        text-transform: uppercase;
        color: $primary-blue;
        border-top: 1px solid $soft-blue-2;
        &:hover {
          background-color: $soft-blue;
        }
        &:active {
          background-color: $soft-blue-2;
        }
        .mat-button-focus-overlay,
        .mat-button-ripple.mat-ripple {
          border-radius: 0;
        }
      }
      &.login-form-link {
        color: $primary-blue;
      }
    }
  }
}
