@import "./../../../core-theme";
@mixin change-slide-toggle-color() {
	.bddc {
		.mat-slide-toggle {
			.mat-slide-toggle-ripple {
				top: calc(50% - 18px);
				left: calc(50% - 18px);
			}
			&.mat-checked {
				.mat-slide-toggle {
					&-thumb-container {
						transform: translate3d(24px, 0, 0);
					}
				}
			}
			&.mat-primary {
				.mat-slide-toggle {
					&-thumb {
						width: 24px;
						height: 24px;
						&-container {
							top: 3px;
							left: 3px;
						}
					}
					&-bar {
						width: 54px;
						height: $squareInputSize;
						border-radius: 40px;
						background-color: $soft-blue-2;
					}
				}
				&.mat-checked {
					.mat-slide-toggle {
						&-bar {
							background-color: $primary-blue;
						}
						&-thumb {
							background-color: $white;
						}
					}
				}
			}
		}
	}
}