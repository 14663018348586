@import "./../../../core-theme";
@mixin mat-card-overrides() {
	.bddc {
		.mat-card {
			padding: 0;
			* {
				&:not(.mat-icon) {
					font-family: $base-font;
				}
			}
			&-title {
				font-family: $base-font;
				color: $primary-blue;
			}
			&-content {
				font-family: $base-font;
			}
			.mat-divider-horizontal.mat-divider-inset {
				position: absolute;
			}
			&.dog-summary-background-blue {
				.mat-card-header-text {
					margin: 16px 0 0 16px;
				}
			}
			&.regular-schedule-card {
				.mat-card-header-text {
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
				}
			}
		}
	}
}
