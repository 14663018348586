@use '@angular/material' as mat;
@import "./vars/bddc-theme";

/*=- Set font for our theme: -=*/
/*
  =- Typography legend: -=
  $display-1, $display-2, $display-3: desktop headings
  $headline, $title, $subheading-2: mobile headings
  $body-1: body text
  $body-2: bold body text
  $button: links and selectors
  $display-2: mat-typography-level(25px, 32px, 700)
  =- End -=
*/

$bddc-typography: mat.define-legacy-typography-config($font-family: "Lato, sans-serif",
    $display-1: mat.define-typography-level(30px, 36px, 800),
    $display-2: mat.define-typography-level(25px, 32px, 700),
    $display-3: mat.define-typography-level(20px, 27px, 700),
    $headline: mat.define-typography-level(24px, 28px, 800),
    $title: mat.define-typography-level(18px, 22px, 700),
    $subheading-1: mat.define-typography-level(20px, 20px, 700),
    $subheading-2: mat.define-typography-level(16px, 20px, 700),
    $body-1: mat.define-typography-level(15px, 22px, 400),
    $body-2: mat.define-typography-level(15px, 22px, 600),
    $button: mat.define-typography-level(14px, 22px, 500),
  );

/*=- Color palette vars from color palette: -=*/
$dark-blue-palette: mat.define-palette($bddc-dark-blue, 900);
$light-blue-palette: mat.define-palette($bddc-light-blue, 700);
$light-blue-2-palette: mat.define-palette($bddc-light-blue, 400);
$gray-blue-palette: mat.define-palette($bddc-gray-blue, 500);
$gray-blue-2-palette: mat.define-palette($bddc-accent, 500);
$blue-gray-palette: mat.define-palette($bddc-accent, 400);
$carousel-blue-palette: mat.define-palette($bddc-accent, 100);
$purple-palette: mat.define-palette($bddc-purple, 300);
$blue-palette: mat.define-palette($bddc-blue, 700);
$cyan-palette: mat.define-palette($bddc-cyan, 400);
$light-cyan-palette: mat.define-palette($bddc-cyan, 100);
$aqua-palette: mat.define-palette($bddc-aqua, 200);
$green-palette: mat.define-palette($bddc-green, 400);
$light-dark-green-palette: mat.define-palette($bddc-light-green, 200);
$light-green-palette: mat.define-palette($bddc-light-green, 50);
$orange-palette: mat.define-palette($bddc-orange, 500);
$light-orange-palette: mat.define-palette($bddc-light-orange, 50);
$red-palette: mat.define-palette($bddc-red, 500);
$red-2-palette: mat.define-palette($bddc-red, 300);
$pink-palette: mat.define-palette($bddc-pink, 50);
$light-pink-palette: mat.define-palette($bddc-light-pink, 50);
$facebook-color-palette: mat.define-palette($bddc-accent, 600);
$twitter-color-palette: mat.define-palette($bddc-accent, 700);
$soft-blue-palette: mat.define-palette($bddc-accent, 50);
$soft-blue-2-palette: mat.define-palette($bddc-accent, 200);
$google-gray-1-palette: mat.define-palette($bddc-accent, 800);
$google-gray-2-palette: mat.define-palette($bddc-accent, 900);

/*=- Color vars from color palette: -=*/
$dark-blue: mat.get-color-from-palette($dark-blue-palette);
$light-blue: mat.get-color-from-palette($light-blue-palette);
$light-blue-2: mat.get-color-from-palette($light-blue-2-palette);
$gray-blue: mat.get-color-from-palette($gray-blue-palette);
$gray-blue-2: mat.get-color-from-palette($gray-blue-2-palette);
$blue-gray: mat.get-color-from-palette($blue-gray-palette);
$carousel-blue: mat.get-color-from-palette($carousel-blue-palette);
$purple: mat.get-color-from-palette($purple-palette);
$blue: mat.get-color-from-palette($blue-palette);
$cyan: mat.get-color-from-palette($cyan-palette);
$light-cyan: mat.get-color-from-palette($light-cyan-palette);
$aqua: mat.get-color-from-palette($aqua-palette);
$green: mat.get-color-from-palette($green-palette);
$light-dark-green: mat.get-color-from-palette($light-dark-green-palette);
$light-green: mat.get-color-from-palette($light-green-palette);
$orange: mat.get-color-from-palette($orange-palette);
$light-orange: mat.get-color-from-palette($light-orange-palette);
$red: mat.get-color-from-palette($red-palette);
$red-2: mat.get-color-from-palette($red-2-palette);
$pink: mat.get-color-from-palette($pink-palette);
$light-pink: mat.get-color-from-palette($light-pink-palette);
$facebook-color: mat.get-color-from-palette($facebook-color-palette);
$twitter-color: mat.get-color-from-palette($twitter-color-palette);
$google-gray-1: mat.get-color-from-palette($google-gray-1-palette);
$google-gray-2: mat.get-color-from-palette($google-gray-2-palette);
$soft-blue: mat.get-color-from-palette($soft-blue-palette);
$soft-blue-2: mat.get-color-from-palette($soft-blue-2-palette);

/*=- Theme color vars for color hooks: -=*/
$primary: mat.define-palette($bddc-primary-blue, 900);
$accent: mat.define-palette($bddc-accent, 500);
$warn: mat.define-palette($bddc-red, 500);
$base: mat.define-palette(mat.$grey-palette);

// $primary-blue: mat-color($primary);
$primary-blue: #003a98;
$primary-blue-bg: rgba($primary-blue, 0.7);
$white: white;
$grey: #555;
$green: #49c5b1;

// Temp var until confirmed by designers:
$flexi-pass-blue: #0b6cde;
$boxShadowColorRGBA: rgba($primary-blue, 0.4);
$calendarSelectedDayBg: #fffae5;
$calendarSunColor: #ffce00;
$base-background-color: #fafafa;
$invoiceStateColorRed: #ef4136;
$invoiceStateColorOrange: #ff9300;
$regular-schedule-card-day-bg: #2b65a9;
$calendarGrey: #dbdfe24d;
$reviewBoxShadow: #658fc033;
$disabledMatCheckbox: #b0b0b0;
$placeHolderImageColor: #dde7f1;

$menuBarShadowColor: #658fc0;
$menuBarShadowColorRGBA: rgba($menuBarShadowColor, 0.2);
$footer-border-palette: mat.define-palette($bddc-primary-blue, 700);
$footer-border: mat.get-color-from-palette($footer-border-palette);

/*=- Font variables: -=*/
$base-font: "Lato", sans-serif;

/*=- Register our theme: -=*/
$bddc-theme: mat.define-light-theme($primary, $accent, $warn);

/*=- Set our theme as active: -=*/
@include mat.all-legacy-component-themes($bddc-theme);

/*=- Commonly used variables: -=*/
/*=--- Border vars: ---=*/
$borderRadiusSize: 22px;
$borderRadiusMediumSize: 16px;
$borderRadiusSmallSize: 8px;
$borderWidthSmallSize: 8px;

/*=--- Box-shadow vars: ---=*/
$boxShadowBlurSize: 12px;
$standardBoxShadowSettings: 1px 2px 20px rgba($light-blue-2, 0.2);

/*=--- Font vars: ---=*/
$generalFontSize: 14px;

/*=--- Input size vars: ---=*/
$inputHeightSize: 44px;
$squareInputSize: 30px;
$squareInputSmallSize: 8px;

/*=--- Spacing vars: ---=*/
$spacingXLarge: 16px;
$spacingLarge: 14px;
$spacingMedium: 12px;
$spacingSmall: 8px;