@import "./../../../core-theme";
@import "./../style-helpers/breakpoint-mixin";

@mixin change-mat-dialog-styling() {
  .bddc {

    .services-calendar-dialog_overlay,
    .mini-calendar-dialog_overlay,
    .verify-password-dialog_overlay,
    .stripe-dialog_overlay,
    .direct-debit-dialog_overlay,
    .message-us-dialog_overlay,
    .photo-view-dialog_overlay,
    .share-photo-dialog_overlay,
    .notification-dialog_overlay,
    .broadcast-dialog_overlay,
    .email-share_overlay,
    .swap-day-dialog_overlay,
    .session-dialog_overlay,
    .dynamic-service-dialog_overlay,
    .add-service-dialog_overlay,
    .regular-service-template-dialog_overlay,
    .tagged-dogs-dialog_overlay {
      background-color: rgba($primary-blue, 0.85);
    }

    .swap-day-dialog_overlay {
      .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
        width: auto !important;
        height: auto !important;
        min-height: 250px;
      }

      .mat-dialog-container {
        border-radius: 8px;

        @include breakpoint("laptop") {
          border-radius: 16px;
          padding: 30px;
        }
      }
    }

    .services-calendar-dialog_overlay {
      .mat-dialog-container {
        padding: 30px;
      }
    }

    .rate-us-dialog_overlay {
      background-color: $primary-blue;
    }

    .services-calendar-dialog_overlay,
    .mini-calendar-dialog_overlay,
    .tagged-dogs-dialog_overlay {
      .mat-dialog-container {
        border-radius: 8px;

        @include breakpoint("laptop") {
          border-radius: 16px;
          padding: 25px 29px;
        }
      }
    }

    .session-dialog_overlay {
      .cdk-overlay-pane {
        height: auto !important;
      }
    }
    .direct-debit-dialog_overlay,
    .stripe-dialog_overlay,
    .session-dialog_overlay,
    .regular-service-template-dialog_overlay {
      .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
        max-width: 90% !important;

        .mat-dialog-container {
          @include breakpoint("laptop") {
            border-radius: 16px;
          }
        }
      }
    }

    .notification-dialog_overlay {
      .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
        .mat-dialog-container {
          border-radius: 8px;
          padding: 0;

          @include breakpoint("laptop") {
            border-radius: 16px;
          }
        }
      }
    }

    .dynamic-service-dialog_overlay {
      .mat-dialog-container {
        overflow: visible;
      }
    }

    .broadcast-dialog_overlay {
      .mat-dialog-container {
        min-height: 100px;
      }
    }

    .verify-password-dialog_overlay,
    .stripe-dialog_overlay,
    .message-us-dialog_overlay,
    .broadcast-dialog_overlay,
    .rate-us-dialog_overlay,
    .dynamic-service-dialog_overlay {
      .mat-dialog-container {
        border-radius: 8px;
        padding: 30px;

        @include breakpoint("laptop") {
          border-radius: 16px;
        }
      }

      @include breakpoint("tablet") {
        .cdk-overlay-pane {
          min-height: 375px;
        }
      }
    }

    .rate-us-dialog_overlay {
      .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
        max-width: 90% !important;
      }
    }

    .mini-calendar-dialog_overlay {

      &:not(.swap-day-dialog_overlay),
      &:not(.session-dialog_overlay) {
        .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
          .mat-dialog-container {
            padding: 0;
          }

          @include breakpoint("tablet-large") {
            width: 786px !important;
            height: 730px !important;
          }

          @include breakpoint("bddc-screen") {
            height: 690px !important;
          }

          @include breakpoint("desktop") {
            height: 715px !important;
          }
        }
      }
    }

    .email-share_overlay {
      .mat-dialog-container {
        border-radius: 8px;
        padding: 30px;

        @include breakpoint("laptop") {
          border-radius: 16px;
        }
      }
    }

    .photo-view-dialog_overlay {
      .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
        height: 100vh !important;

        @media (max-width: 1023px) {
          max-width: 100% !important;
        }
      }

      .mat-dialog-container {
        padding: initial;
        background-color: transparent;
        box-shadow: none;
        overflow: initial;
      }
    }

    .share-photo-dialog_overlay {
      .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
        max-width: 343px !important;

        @include breakpoint("tablet") {
          max-width: 377px !important;
        }
      }

      .mat-dialog-container {
        background-color: $white;
        border-radius: 8px;
        padding: 30px;

        @include breakpoint("laptop") {
          border-radius: 16px;
        }
      }
    }

    .review-dialog_overlay,
    .dynamic-service-dialog_overlay {
      background: $primary-blue;

      .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
        max-width: 343px !important;

        @include breakpoint("tablet") {
          max-width: 377px !important;
        }
      }

      .mat-dialog-container {
        margin: 0 20px;

        @include breakpoint("phone-iphone6") {
          margin: 0;
        }

        padding: 30px;
        border-radius: 16px;
      }
    }

    .banked-days-dialog_overlay {
      background: $primary-blue;

      .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
        max-height: 80vh !important;

        @include breakpoint("tablet") {
          max-width: 550px !important;
        }
      }

      .mat-dialog-container {
        margin: 0 20px;

        @include breakpoint("phone-iphone6") {
          margin: 0;
        }

        padding: 30px;
        border-radius: 16px;
      }
    }

    .review-dialog_overlay {
      .mat-dialog-container {
        padding: 30px;
        border-radius: 16px;
      }
    }

    .services-calendar-cancel_overlay {
      .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
        @include breakpoint("phone-iphone6") {
          // height: 338px !important;
        }

        @include breakpoint("tablet") {
          // height: 338px !important;
        }

        @include breakpoint("tablet-large") {
          // height: 306px !important;
        }
      }

      &.swap-day-dialog_overlay {
        .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
          @include breakpoint("tablet-large") {
            height: 496px !important;
          }
        }
      }
    }

    .mini-calendar-dialog_overlay {
      .cdk-overlay-pane:not(.warning):not(.success):not(.danger) {
        @include breakpoint("tablet-large") {
          width: 803px !important;
          height: 734px !important;
        }
      }
    }
  }
}