@import "./../../../core-theme";

@mixin change-mat-icon-styling() {
  .bddc {
    mat-icon {
      &.action-group_button-icon {
        width: 15px;
        height: 15px;
      }

      &.arrow-text-button_icon.is-white {
        color: $white;
      }

      &.login-form_password-icon {
        cursor: pointer;
        color: $gray-blue-2;
      }

      &.has-rounded-container {
        svg {
          width: 14px;
        }
      }

      &.rounded-icon {
        display: inline;

        svg {
          width: 40px;
          height: 40px;
        }
      }

      &.mat-primary {
        svg .a {
          fill: $primary-blue;
        }
      }
    }

    .material-icons {
      font-family: "Material Icons" !important;
    }

    .photo-view-dialog_actions {
      mat-icon {
        &.mat-icon {
          width: 17px;
          height: 17px;
          margin-right: 5px;
        }
      }
    }

    .exclamation-icon {
      svg .a {
        fill: $red;
      }
    }
  }
}