@import "./../../../core-theme";

@mixin notification-banner-styling {
	.bddc {
		.cdk-overlay-pane.success {
			.mat-dialog-container {
				@include dialog-styling(#5fd370);
			}
		}

		.cdk-overlay-pane.warning {
			.mat-dialog-container {
				@include dialog-styling(#ff9300);
			}
		}

		.cdk-overlay-pane.danger {
			.mat-dialog-container {
				@include dialog-styling(#ef4136);
			}
		}
	}
}

@mixin dialog-styling($color) {
	background: $color;
	padding: 15px;
	border-radius: 12pt;
	min-height: auto;
}