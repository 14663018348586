@import "./../../../core-theme";
@mixin override-mat-icon-color() {
	.mat-icon {
		&.sort svg {
			.a,
			.b,
			.c {
				fill: $white;
				stroke: initial;
			}
		}
		&.mat-light-blue {
			color: $light-blue;
		}
		&.mat-gray-blue {
			color: $gray-blue;
		}
		&.mat-red svg .a {
			fill: $red;
		}
		&.mat-green svg .a {
			fill: $green;
		}

		&.icon-flipped {
			transform: rotate(90deg);
		}
	}
}
