@import "./../../core-theme";

@mixin change-swiper-styling() {
  .bddc {
    swiper>.swiper.s-wrapper:not(.photo-viewer, .services-booking-card) {
      .swiper-wrapper .swiper-slide {
        touch-action: none;
      }
    }

    .swiper-container-home-page,
    .swiper-container-quick-booking {
      .swiper.s-wrapper .swiper-wrapper .swiper-slide {
        overflow: visible;
        padding-left: 10px;
        padding-top: 10px;
        border-radius: 16px;
        max-width: 262px;

      }

      .swiper-wrapper {
        .override-margin {

          @include breakpoint("phone-pixel-2") {
            margin-right: 0 !important;
          }

          @include breakpoint("phone-iphone6") {
            margin-right: 0 !important;
          }
        }
      }
    }

    swiper {
      &.swiper-container-home-page {
        &.quick-booking {
          height: 150px;

          @include breakpoint("tablet") {
            height: 225px;
          }

          &>.swiper.s-wrapper {
            width: calc(100% - 20px);
            height: 332px;
          }
        }

        &.service-card {
          height: 352px;
        }


        &.home-override {
          height: 135px;

          @include breakpoint("tablet-large") {
            height: 235px;
          }

          &>.swiper.s-wrapper {
            width: calc(100% - 20px);
            height: 145px;

            @include breakpoint("tablet-large") {
              height: 235px;
            }

          }
        }
      }

      &.swiper-container-bookings {
        height: auto;
        flex-flow: row nowrap;

        .mat-form-field:not(.sleep-over-textarea) {
          width: 100% !important;

          .mat-form-field-flex {
            width: 100% !important;
          }

          @include breakpoint("phone-iphone5") {
            max-width: 234px !important;
          }

          @include breakpoint("phone-iphone6") {
            max-width: 287px !important;
          }
        }

        .swiper-button {

          &-next,
          &-prev {
            outline: none;

            @include breakpoint("laptop") {
              position: fixed;
              top: 50vh;
            }
          }

          &-prev {
            @include breakpoint("laptop") {
              left: 86px;
            }
          }

          &-next {
            @include breakpoint("laptop") {
              right: 86px;
            }
          }
        }

        .sleepover-info-card_sleepover {
          width: 100%;

          ng-select {
            display: flex;
            width: 100%;
            max-width: 287px;

            .ng-dropdown-panel.ng-select-bottom,
            .ng-dropdown-panel.ng-select-top {
              max-width: 287px !important;
              width: 100% !important;
            }

            @include breakpoint("laptop") {
              margin-left: auto;
            }
          }
        }

        &>.swiper.s-wrapper {
          display: flex;
          align-content: center;
          width: calc(100% - 64px);

          @include breakpoint("tablet") {
            width: 100%;
          }

          // Had to you use own media query as this is breaking desktop carousel
          @media only screen and (max-width: 600px) {
            .swiper-wrapper .swiper-slide {
              padding: 0 5px;
              overflow-x: hidden;

              &:first-child {
                &.swiper-slide-active {
                  padding: 0 5px 0 0;
                }
              }

              &:last-child {
                padding: 0 0 0 5px;
              }
            }
          }
        }

        .swiper-container form mat-checkbox,
        .swiper-container form .mini-calendar-picker,
        .swiper-container form mat-radio-group,
        .swiper-container form button {
          pointer-events: all;
        }
      }

      &.swiper-photo-carousel_slider {
        height: auto;
        min-height: 200px;
        flex-flow: row nowrap;

        .swiper-container {
          display: flex;
          align-content: center;
          width: calc(100% - 40px);

          @include breakpoint("tablet") {
            width: 100%;
            overflow: hidden;
          }

          .swiper-wrapper {
            .slider-item {
              .image {
                height: 244px;
                width: calc(100% - 20px);
                margin: 0 10px;
                border-radius: 8px;
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;

                @include breakpoint("tablet") {
                  height: 170px;
                  margin: 0 10px;
                }

                @include breakpoint("laptop-large") {
                  width: 300px;
                  height: 244px;
                  margin: 0 15px;
                }

                @include breakpoint("desktop") {
                  width: 360px;
                  height: 274px;
                  margin: 0 auto;
                }
              }

              &.swiper-slide-prev,
              &.swiper-slide-next {
                .image {
                  opacity: 0.2;
                  height: 200px;
                  margin-top: 15px;

                  @include breakpoint("tablet") {
                    opacity: 1;
                    height: 150px;
                    margin-top: 10px;
                  }

                  @include breakpoint("laptop-large") {
                    width: 310px;
                    height: 229px;
                    margin-top: 5px;
                  }

                  @include breakpoint("desktop") {
                    width: 330px;
                    height: 249px;
                    margin-top: 5px;
                  }
                }
              }
            }
          }

          .swiper-slide {
            .photo-carousel_count {
              opacity: 0;
            }

            &-active {
              opacity: 1;
              padding: 0;

              .photo-carousel_count {
                opacity: 1;
              }
            }
          }

          .swiper-button-prev {
            left: 0;
          }

          .swiper-button-next {
            right: 0;
          }
        }
      }
    }

    .swiper-container:not(.photo-viewer) {
      max-width: 100%;
      overflow: initial;

      .mat-form-field-flex {
        width: 287px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        pointer-events: all;
        background-size: 15px 27px;
        width: 15px;
        height: 27px;
        outline: none !important;

        @media (max-width: 767px) {
          z-index: 9;
          top: 100px;
        }
      }

      .swiper-button-prev {
        background-image: url("../../../images/icons/slider-chevron-left.svg");

        @media (max-width: 767px) {
          left: -25px;
        }
      }

      .swiper-button-next {
        background-image: url("../../../images/icons/slider-chevron-right.svg");

        @media (max-width: 767px) {
          right: -25px;
        }
      }

      @include breakpoint("laptop") {
        overflow: hidden;
      }
    }

    .swiper-container {
      .swiper-slide-active {
        padding: 0 15px;
      }

      .swiper-slide {
        &:not(.recommended, .book-again) {
          overflow: hidden !important;
        }
      }
    }

    .photo-view-dialog_swiper {
      .swiper-container {
        .swiper-slide {
          display: flex;
          justify-content: center;

          &-active {
            margin-left: 37px;
            // margin: 0 9vw;
            padding: 0;

            @include breakpoint("phone-average") {
              margin: 0 24px;
            }

            @include breakpoint("phone-iphone6") {
              margin: 0;
            }

            @include breakpoint("phone-pixel-2") {
              margin: 0;
            }

            @include breakpoint("iphone-plus") {
              margin: 0 37px;
            }

            @include breakpoint("tablet") {
              margin: 0;
            }
          }

          .image {
            width: 247px;
            height: 192px;
            border-radius: 8px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @include breakpoint("phone-average") {
              width: 287px;
              height: 230px;
            }

            @include breakpoint("phone-iphone6") {
              margin-left: 33px;
              width: 273px;
            }

            @include breakpoint("phone-pixel-2") {
              margin-left: 0;
              width: 331px;
            }

            @include breakpoint("iphone-plus") {
              margin-right: 74px;
              width: 334px;
            }

            @include breakpoint("tablet") {
              margin-right: 0;
              width: 508px;
              height: 508px;
              border-radius: 16px;
            }

            @include breakpoint("tablet-medium") {
              width: 660px;
              height: 400px;
            }

            @include breakpoint("tablet-large") {
              height: 420px;
            }

            @include breakpoint("bddc-screen") {
              width: 582px;
            }
          }

          &:not(.swiper-slide-active) {
            .image {
              max-height: 160px;
              margin: 15px 145px;
              max-width: 227px;

              @include breakpoint("phone-average") {
                max-height: 200px;
              }

              @include breakpoint("phone-iphone6") {
                max-width: 291px;
                height: 200px;
              }

              @include breakpoint("tablet-large") {
                max-height: 420px;
                height: 420px;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }

    .swiper-container-home-page {
      display: flex;
      flex-direction: inherit;
      min-width: 0;
      min-height: 0;
      -webkit-box-direction: inherit;
      -webkit-box-orient: inherit;
      flex: 1 1 auto;
      box-sizing: border-box;
    }

    .swiper-container-home-page .swiper-container {
      padding: 10px;

      .swiper-slide-active {
        padding: 0;
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    .swiper-slide-active {
      padding-left: 15px;
      padding-right: 15px;
    }

    .swiper-container:not(.photo-viewer) {
      max-width: 100%;
      overflow: initial;

      .mat-form-field-flex {
        width: 287px;
      }

      @include breakpoint("tablet-large") {
        overflow: hidden;
      }
    }

    .photo-view-dialog_swiper {
      @include breakpoint("tablet-large") {
        height: 100%;
        overflow: hidden;
      }

      swiper {
        height: 290px;

        @include breakpoint("tablet-large") {
          width: auto;
          height: 100%;
        }

        &>.swiper.s-wrapper {
          // max-width: 88%;
          overflow: visible;

          @media (width: 375px) {
            max-width: 92%;
          }
        }

        .swiper-wrapper .swiper-slide {
          @include breakpoint("tablet-large") {
            display: flex;
            padding: 0;
          }
        }
      }

      .swiper-wrapper {
        height: auto !important;

        @include breakpoint("tablet-large") {
          height: 100%;
        }

        .swiper-slide {
          justify-content: center;
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        @include breakpoint("tablet-large") {
          top: 210px;
          width: 16px;
          height: 28px;
          background-size: contain;
          outline: none !important;
        }
      }

      .swiper-button-prev {
        background-image: url("../../../images/icons/carousel-left-arrow_icon.svg");
        outline: none !important;

        @include breakpoint("tablet") {
          left: -12px;
        }

        @include breakpoint("tablet-large") {
          left: 10px;
        }
      }

      .swiper-button-next {
        background-image: url("../../../images/icons/carousel-right-arrow_icon.svg");
        outline: none !important;

        @include breakpoint("tablet") {
          right: -12px;
        }

        @include breakpoint("tablet-large") {
          right: 10px;
        }
      }

      .swiper-container {
        @include breakpoint("tablet-large") {
          height: 100%;
        }
      }

      .swiper-pagination {
        pointer-events: none;
      }

      .swiper-container-horizontal>.swiper-pagination-bullets {
        bottom: 36px;
        width: 140px !important;

        @include breakpoint("tablet-large") {
          bottom: 25px;
        }
      }

      .swiper-pagination-bullet {
        background: $white;
      }
    }
  }

  // Homepage swipers
  .swiper-custom-prev,
  .swiper-custom-next {
    background-size: 15px 27px;
    width: 15px;
    height: 27px;
    position: absolute;
    top: 235px;
    display: none;
    outline: none;
  }

  @include breakpoint("tablet") {
    .swiper-custom-prev {
      display: block;
    }

    .swiper-custom-next {
      display: block;
    }
  }

  .swiper-custom-prev,
  .swiper-custom-next {
    cursor: pointer;

    &.swiper-button-disabled {
      pointer-events: none;
      opacity: 0.35;
    }
  }

  .swiper-custom-prev {
    background-image: url("../../../images/icons/slider-chevron-left.svg");
    left: -30px;
  }

  .swiper-custom-next {
    background-image: url("../../../images/icons/slider-chevron-right.svg");
    right: -30px;
  }
}