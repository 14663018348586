@import "./../../../core-theme";
@mixin change-side-nav-styling() {
	.bddc {
		mat-sidenav {
			&.mat-sidenav {
				background-color: $green;
			}
		}
	}
}
