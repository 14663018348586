@import "./../../../core-theme";
@import "./../style-helpers/breakpoint-mixin";

@mixin paginator() {

  mat-paginator,
  .banked-days-paginator {
    .mat-form-field {
      width: 125px;
    }

    .mat-paginator-page-size {
      margin-right: 0px;
    }

    .mat-form-field-flex {
      width: 125px;
    }

    .mat-select-arrow-wrapper {
      padding-right: 10px;
    }
  }
}