@import "./../../../core-theme";

@mixin mat-form-styling() {
  .bddc {
    mat-form-field {
      &.vaccination-uploads {
        .mat-form-field-infix {
          padding-left: 15px;
          width: fit-content;
        }

        .mat-form-field-flex {
          width: 235px;
        }
      }

      &.sleep-over-textarea {
        height: auto;
        width: 100% !important;

        .mat-form-field-flex {
          border-radius: 0;
          background-color: transparent;
          align-items: initial;
          border-style: none;
          width: 100%;
          border: 0;

          .mat-form-field-infix {
            padding-right: 0px;
          }

          .mat-form-field-label {
            &.mat-form-field-empty {
              left: 1em;
              top: 0;
            }
          }
        }

        &.mat-focused .mat-form-field-flex {
          border: 0;
        }
      }

      &.sleepover {
        .mat-form-field-flex {
          display: contents;
        }

        .mat-form-field-infix {
          width: auto;
        }
      }

      &.feedback_textarea {
        .mat-form-field-flex {
          height: 100px;
          overflow: hidden;
          padding: 2px 0;

          textarea {
            margin-top: -10px;
            resize: none;
          }

          .mat-form-field-label-wrapper {
            label {
              margin-left: 30px;
              margin-top: 3px;
            }
          }

          .mat-form-field-infix span.mat-form-field-label-wrapper {
            top: -15px;
            left: -15px;
          }
        }

        &.ng-invalid.mat-form-field-invalid.ng-touched {
          textarea {
            background-color: transparent;
          }
        }
      }

      &.rating_textarea {
        height: auto;
        width: 100% !important;

        .mat-form-field-wrapper {
          padding-top: 30px;
          padding-bottom: 0;
        }

        .mat-form-field-flex {
          display: contents;
          background-color: transparent;
          align-items: initial;
          border-style: none;
          width: 100%;
          border: 0;

          .mat-form-field-infix {
            padding-right: 0px;
            min-width: none;
            width: auto;
          }

          .mat-form-field-label {
            &.mat-form-field-empty {
              left: 1em;
              top: 0;
              white-space: pre-wrap;
            }
          }

          textarea {
            margin-top: -10px;
            resize: none;
            width: 95%;

            &.invalid {
              border: 1px solid #ef4236;
              background-color: #faf1f0;
            }
          }
        }

        &.mat-focused .mat-form-field-flex {
          border: 0;
        }
      }
    }

    .max-width {
      @media (max-width: 1024px) {
        mat-form-field {
          &.mat-form-field {
            width: 319px;

            .mat-form-field-flex {
              width: 299px;
            }
          }
        }
      }
    }

    .sleepover-info-card {
      .mat-form-field.sleep-over-textarea {
        .mat-form-field-flex {
          display: contents;
        }

        .mat-form-field-infix {
          width: 100% !important;
        }

        textarea {
          height: auto;
          width: 100%;
          padding: 10px 15px 10px 15px;

          &.invalid {
            border: 1px solid #ef4236;
            background-color: #faf1f0;
          }
        }

        &-wrapper {
          // Overriding datepicker bottom padding..
          padding-bottom: 0 !important;
        }
      }
    }

    .sleep-over-textarea {
      .mat-form-field-flex {
        width: 100% !important;
      }
    }
  }
}