@import "./../../core-theme";
@import "./style-helpers/breakpoint-mixin";
@mixin typography() {
  .bddc {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    span.mat-body-1 {
      color: $primary-blue;
    }
    h1.mat-display-1,
    h2.mat-display-2,
    *.mat-display-2 {
      margin: 0;
    }
    a {
      &:not(a.mobile-menu_link) {
        span {
          color: $primary-blue;
        }

        &:hover {
          span {
            color: $white;
          }
        }
      }
      &.mat-button {
        &:hover {
          span {
            color: $primary-blue !important;
          }
        }
      }
    }
    h1 {
      &.mat-headline {
        margin-bottom: 16px;
      }
    }
    p {
      &.forgot-form_instructions {
        margin-top: 0;
        margin-bottom: 30px;
        font-weight: 300;
      }
    }
    .white-text {
      color: $white;
    }
    .remove-styling {
      text-decoration: none;
    }
    .mat-card-header-text {
      width: 100%;
    }
  }
  mat-card-content {
    p {
      &.mat-body-1 {
        margin-top: 7px !important;
        margin-bottom: 13px !important;
      }
    }
  }
  .white-text {
    color: $white;
  }
  .remove-styling {
    text-decoration: none;
  }
  .mat-card-header-text {
    width: 100%;
  }
  .bddc {
    .mat-headline {
      text-align: center;
      &:not(.u-margin-bottom-15) {
        margin-bottom: 24px !important; // cannot avoid this override
      }
      &.u-margin-bottom-15 {
        margin-bottom: 15px !important; //as per design - cannot avoid this override
      }
    }
    h2 {
      &.mat-display-2 {
        &.is-component {
          font-size: 18px;
          line-height: 22px;
          @include breakpoint("laptop") {
            font-size: 25px;
            line-height: 32px;
          }
        }
      }
    }
  }
  span.mat-body-1 {
    &.red-text {
      color: $red;
    }
  }
  .copy-right-text {
    width: 100%;
    text-align: center;
    color: $gray-blue;
    padding-bottom: 30px;
    @include breakpoint("tablet") {
      position: absolute;
      bottom: 0;
      color: $white;
    }
    &_link {
      font-weight: 600;
    }
  }
  // Responsive typography overrides
  .mat-display-1 {
    font: 800 24px/28px Lato, sans-serif !important;
    @include breakpoint("laptop") {
      font: 800 30px/36px Lato, sans-serif !important;
    }
  }
  .mat-display-2 {
    font: 700 18px/22px Lato, sans-serif !important;
    @include breakpoint("laptop") {
      font: 700 25px/32px Lato, sans-serif !important;
    }
  }
  .mat-display-3 {
    font: 700 16px/20px Lato, sans-serif !important;
    @include breakpoint("laptop") {
      font: 700 20px/27px Lato, sans-serif !important;
    }
  }
}
