@import "./../../../core-theme";
@import "./../style-helpers/breakpoint-mixin";
@mixin change-link-button-styling() {
	.bddc {
		.mat-button {
			text-transform: uppercase;
			line-height: 22px;
			&-ripple {
				&.mat-ripple {
					border-radius: $borderRadiusSize;
					@include breakpoint("tablet") {
						border-radius: 0;
					}
				}
			}
			&-focus-overlay {
				border-radius: $borderRadiusSize;
			}
			&.login-form-link {
				text-transform: none;
			}
			&.compact-bordered {
				width: 100px;
				height: 35px;
				border: 1px solid $primary-blue;
				color: $primary-blue;
				border-radius: 18px;
				&:disabled {
					border-color: rgba(0, 0, 0, 0.26);
				}
			}
			&.flat-button_round {
				width: 125px;
				height: 21px;
				border-radius: 11px;
				font-size: 13px;
				line-height: 18px;
				text-transform: none;
				font-weight: bold;
				&.warn {
					background-color: $pink;
					color: $red;
				}
				&.okay {
					background-color: $light-green;
					color: $green;
				}
			}
			&.flat-button_round_debit {
				width: auto;
				height: auto;
				border-radius: 11px;
				font-size: 13px;
				line-height: 18px;
				text-transform: none;
				font-weight: bold;
				&.okay {
					background-color: $light-green;
					color: $green;
				}
			}
			.mat-button-focus-overlay {
				@include breakpoint("tablet") {
					border-radius: 0;
				}
			}
		}
		.navigation-items {
			li {
				a {
					&.mat-button {
						.mat-button-focus-overlay,
						.mat-button-ripple {
							@include breakpoint("laptop") {
								opacity: 0;
							}
						}
					}
				}
			}
		}
		.mat-button-link {
			&.arrow-text-button {
				border-radius: 0;
				&:hover {
					.arrow-text-button_text,
					.arrow-text-button_icon {
						color: rgba($white, 0.6);
					}
				}
				&:active {
					.arrow-text-button_text,
					.arrow-text-button_icon {
						color: rgba($white, 0.8);
					}
				}
			}
		}
		.mat-mini-fab {
			&.mat-accent {
				span.mat-button-wrapper {
					padding: 0;
				}
			}
		}
	}
}