@import "./../../../core-theme";
@mixin change-accordion-styling() {
  .bddc {
    mat-accordion {
      &.mat-accordion {
        &.according-stepper {
          mat-expansion-panel.mat-expansion-panel {
            display: inline-block;
            width: calc(100% - 70px);
            margin-bottom: 30px;
            margin-top: 0;
            background-color: $white;
            border-radius: 8px;
            box-shadow: 1px 2px 20px rgba($light-blue-2, 0.2);
            &.mat-expanded.mat-expansion-panel-spacing {
              .mat-expansion-panel-content {
                padding: 0 12px 20px 12px;
                .mat-expansion-panel-body {
                  padding: 0;
                }
              }
            }
            &:after {
              clear: both;
            }
            .mat-expansion-panel-header {
              padding: 0 12px;
              span {
                font-weight: bold;
                color: $light-blue;
                .incomplete {
                  color: grey;
                }
              }
            }
            .mat-expansion-panel-content {
              .mat-expansion-panel-body {
                padding: 0;
                color: $gray-blue;
              }
            }
          }
        }
      }
      &.flat {
        .mat-accordion {
          background-color: transparent;
          color: transparent;
          .mat-expansion-panel:not([class*="mat-elevation-z"]) {
            box-shadow: none;
          }
        }
        & > .mat-expansion-panel {
          border-radius: 0;
          box-shadow: none;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: calc(100% - 48px); // (minus the padding)
            height: 1px;
            background-color: $soft-blue-2;
          }
        }
        & > .mat-expansion-panel-spacing {
          margin: 0;
        }
        .mat-expansion-panel-header {
          padding: 15px 24px;
          &-title {
            color: $light-blue;
          }
          .mat-expansion-indicator::after {
            color: $blue-gray;
          }
        }
        .mat-expansion-panel-content {
          p.mat-body-1 {
            color: $gray-blue;
          }
        }
      }
    }
  }
}
