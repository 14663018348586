@import "./../../../core-theme";
@mixin change-mat-card-styling() {
	// try to override this silly class styling
	.bddc {
		mat-card {
			&.thumbnail-icon-card {
				box-shadow: 1px 2px 20px rgba($light-blue-2, 0.2) !important; // color provided in design does not match color pallete, used the closest match to it.
			}
		}
	}
}