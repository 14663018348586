@import "./../../../core-theme";
@mixin change-checkbox-color() {
  .bddc {
    .mat-checkbox {
      &-frame {
        background-color: $soft-blue-2;
        border-radius: $borderRadiusSmallSize;
        border-style: none;
        border-width: initial;
        width: $squareInputSize;
        height: $squareInputSize;
      }
      &-label {
        color: $primary-blue;
      }
      &-inner-container {
        width: $squareInputSize;
        height: $squareInputSize;
        border-radius: $borderRadiusSmallSize;
        position: relative;
        overflow: hidden;
        .mat-checkbox-ripple {
          left: calc(50% - 15px);
          top: calc(50% - 15px);
          width: $squareInputSize;
          height: $squareInputSize;
        }
        .mat-ripple-element {
          border-radius: $borderRadiusSmallSize;
        }
      }
      &-layout {
        align-items: center;
      }
    }

    .mat-checkbox-disabled {
      .mat-checkbox-frame {
        background-color: $disabledMatCheckbox;
        border-style: none;
      }
    }
  }
}
