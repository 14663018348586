@import "./../../../core-theme";
@mixin change-mat-divider-styling() {
	.bddc {
		mat-divider {
			&.divider_soft-blue {
				border-top-color: $soft-blue;
				border-top-width: 2px;
				margin: 15px 0;
			}
		}
	}
}