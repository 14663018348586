@import "./../../core-theme";

@mixin change-slick-styling() {
  .slick-arrow.swiper-next-arrow {
    background-image: url("../../../images/icons/slider-chevron-right.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    position: fixed;
    top: 50%;
    right: 8px;
    cursor: pointer;
  }

  .slick-arrow.swiper-prev-arrow {
    background-image: url("../../../images/icons/slider-chevron-left.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    position: fixed;
    top: 50%;
    left: 20px;
    z-index: 1000;
    cursor: pointer;
  }
}
