@import "./../../../core-theme";
@import "./../style-helpers/breakpoint-mixin";
@mixin change-tabs-style() {
  .bddc {
    .mat-tab-group {
      .mat-ink-bar {
        visibility: hidden !important;
      }
      .mat-tab-header {
        border: none;
      }
      .mat-tab-label {
        color: rgba($primary-blue, 0.3);
        width: 50%;
        &:first-child {
          border-right: 1px solid $soft-blue-2;
        }
        .mat-tab-label-content {
          font-size: 16px;
          .mat-icon {
            margin-right: $spacingMedium;
            opacity: 0.3;
          }
        }
        &-active {
          .mat-tab-label-content {
            color: $primary-blue;
            font-weight: bold;
            .mat-icon {
              opacity: 1;
            }
          }
        }
      }
      .mat-tab-labels {
        justify-content: space-around;
      }
      &.calendar-tabs {
        .mat-tab-body-content {
          display: flex;
          flex-direction: column;
        }
        .mat-tab-header {
          border-bottom: 0;
          @include breakpoint("tablet") {
            display: none;
          }
        }
        @include breakpoint("tablet") {
          width: 100%;
          .mat-tab-body-content {
            flex-direction: row;
          }
        }
      }

      &.services-tabs {
        .mat-tab-header {
          .mat-tab-labels {
            min-height: 50px;
          }
        }
        .mat-tab-body {
          margin-top: 10px;
        }
        .mat-tab-body-wrapper,
        .mat-tab-body,
        .mat-tab-body-content,
        .mat-tab-body-active {
          overflow: visible;
        }
      }

      &.dog-profile-tabs {
        .mat-tab-header {
          border-bottom: 0;
          margin-left: 20px;

          @include breakpoint("tablet") {
            margin-left: 40px;
          }

          .mat-tab-label {
            width: 100%;
            &-container {
              flex-grow: 0;
              .mat-tab-label {
                height: 35px;
                background: $soft-blue-2;
                max-width: 93px;
                border-radius: 8px 8px 0 0;
                margin-left: 2.5px;
                margin-right: 2.5px;

                &-content {
                  color: $primary-blue;
                }

                &-active {
                  background: $primary-blue;
                  color: $white;
                  box-shadow: 1px 2px 10px rgba($menuBarShadowColor, 0.6);

                  .mat-tab-label-content {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .mat-tab-label {
      opacity: initial;
    }

    .bddc-calendar {
      .mat-tab-group .mat-tab-header {
        margin-left: 0;
      }

      .mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-label {
        max-width: 100%;
      }
    }
  }
}
